import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import type { PlanType } from '.';

interface Props {
  specialOffer?: string;
  plan: PlanType;
  price: string;
  offerPrice: string;
}

function splitPrice(price: string) {
  return price.toString().split('.');
}

const PriceContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  minHeight: 120
});

const SpecialOfferTitle = styled(Typography)({
  fontSize: 14,
  fontFamily: 'Open Sans, sans-serif',
  color: '#fff',
  textAlign: 'center',
  position: 'absolute',
  top: 52,
  right: 0,
  transform: 'rotate(45deg) scale(.777,.82)',
  transformOrigin: '100% 100%',
  width: 143,
  padding: 2,
  backgroundImage: '-webkit-linear-gradient(180deg, #e62e2d, #eb7e25)'
});

const PlanPrice = styled(Typography)({
  fontSize: 42,
  fontFamily: 'Open sans, sans-serif',
  letterSpacing: '2.1px',
  lineHeight: 1.36,
  color: 'var(--color-plan, #5BC005)',
  fontWeight: 600,
  marginTop: 6,
  '&.free': {
    marginBottom: 24
  },
  '@media only screen and (max-width: 425px)': {
    fontSize: 40,
    lineHeight: '62px'
  },
  '&.isSpecialOffer': {
    marginTop: 0
  }
});

const SupPrice = styled('sup')({
  fontSize: 24,
  verticalAlign: 'baseline',
  position: 'relative',
  top: '-0.5em'
});

const OldPriceFig = styled('sup')({
  position: 'absolute',
  width: '70%',
  top: '45%',
  left: '15%',
  transform: 'rotate(345deg)',
  borderBottom: '1px solid #fff'
});

const OldPrice = styled(Typography)({
  position: 'relative',
  fontSize: 16,
  fontFamily: 'Open sans, sans-serif',
  letterSpacing: '2.1px',
  color: 'var(--color-plan, #fff)',
  fontWeight: 600,
  marginBottom: 12,
  borderRadius: 45,
  padding: '4px 10px',
  '& $supPrice': {
    fontSize: 12
  }
});

const PaymentPeriod = styled(Typography)({
  textAlign: 'center',
  fontSize: 14,
  marginTop: -10,
  fontFamily: 'Open sans, sans-serif',
  letterSpacing: '0.8px',
  lineHeight: '22px',
  color: '#414042',
  textTransform: 'uppercase',
  fontWeight: 600,
  marginBottom: 8,
  '@media only screen and (max-width: 425px)': {
    fontSize: 16,
    lineHeight: '25px'
  }
});

const SpecialOfferSubtitle = styled(Typography)(({}) => ({
  fontSize: 12,
  fontFamily: 'Open sans, sans-serif',
  color: '#414042',
  fontWeight: 600,
  marginTop: 12,
  textTransform: 'uppercase',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  background: '-webkit-linear-gradient(180deg, var(--color-from), var(--color-to))'
}));

export function SpecialOfferPlan(props: { plan: PlanType; offer?: string }) {
  return (
    <>
      <SpecialOfferTitle>{props.offer}</SpecialOfferTitle>
      <SpecialOfferSubtitle>Limited Offer!</SpecialOfferSubtitle>{' '}
    </>
  );
}

function FormattedPrice({ price, isCrossed }: { price: string; isCrossed?: boolean }) {
  const [priceInt, priceDecimals] = splitPrice(price);
  return (
    <>
      <SupPrice>$</SupPrice>
      {priceInt}
      <SupPrice>{`.${priceDecimals || '00'}`}</SupPrice>
      {isCrossed ? <OldPriceFig /> : null}
    </>
  );
}

export function PriceItem({ specialOffer, plan, price: nonOfferPrice, offerPrice }: Props) {
  const isSpecialOffer = specialOffer != null;
  const price = isSpecialOffer ? offerPrice : nonOfferPrice;
  return (
    <PriceContainer>
      {isSpecialOffer ? <SpecialOfferPlan offer={specialOffer} plan={plan} /> : null}
      <PlanPrice className={isSpecialOffer ? 'isSpecialOffer' : undefined}>
        <FormattedPrice price={price} />
      </PlanPrice>
      {isSpecialOffer ? (
        <OldPrice>
          <FormattedPrice price={nonOfferPrice} isCrossed={true} />
        </OldPrice>
      ) : null}
      {plan !== 'Free' ? <PaymentPeriod>/ month</PaymentPeriod> : null}
    </PriceContainer>
  );
}
