import { Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export const PlanButton = styled(Button)({
  fontSize: 14,
  fontFamily: 'Open sans, sans-serif',
  letterSpacing: '1.4px',
  lineHeight: '29px',
  width: '100%',
  color: 'var(--color-font)',
  backgroundColor: 'var(--color-bg, #67C016)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '10px',
  fontWeight: 600,
  border: '1px solid #000',
  boxShadow: '2px 2px 0px #000',
  '& span': {
    textTransform: 'uppercase'
  },
  '&.Mui-disabled': {
    backgroundColor: '#eee'
  },
  '&:hover': {
    backgroundColor: 'var(--color-hover, #2A8000)',
    textDecoration: 'none'
  },
  '@media only screen and (max-width: 425px)': {
    fontSize: 16,
    lineHeight: '25px'
  }
});
