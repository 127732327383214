import { Box, Container, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { UnlimitedIcon } from './UnlimitedIcon';

export const SubFigText = styled(Box)({
  marginTop: 30,
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media only screen and (max-width: 425px)': {
    flexDirection: 'column',
    '& svg': {
      marginBottom: '10px!important',
      marginTop: '10px!important'
    }
  }
});

const SubText = styled(Typography)({
  fontSize: 24,
  letterSpacing: '2.4px',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  lineHeight: '36px',
  textTransform: 'uppercase',
  color: '#000',
  textAlign: 'center'
});

export function WidgetsPlanHeader() {
  return (
    <Container maxWidth="lg">
      <Box marginTop="35px">
        <SubFigText>
          <SubText>Unlimited websites</SubText>
          <UnlimitedIcon />
          <SubText>Unlimited page views</SubText>
        </SubFigText>
        <SubFigText>
          <SubText style={{ fontWeight: 400 }}>in all our plans!</SubText>
        </SubFigText>
      </Box>
    </Container>
  );
}
